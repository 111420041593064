exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-afterservice-tsx": () => import("./../../../src/pages/afterservice.tsx" /* webpackChunkName: "component---src-pages-afterservice-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-engagering-carat-0-1-カラット-tsx": () => import("./../../../src/pages/engagering/carat/0.1カラット.tsx" /* webpackChunkName: "component---src-pages-engagering-carat-0-1-カラット-tsx" */),
  "component---src-pages-engagering-carat-0-2-カラット-tsx": () => import("./../../../src/pages/engagering/carat/0.2カラット.tsx" /* webpackChunkName: "component---src-pages-engagering-carat-0-2-カラット-tsx" */),
  "component---src-pages-engagering-carat-0-3-カラット-tsx": () => import("./../../../src/pages/engagering/carat/0.3カラット.tsx" /* webpackChunkName: "component---src-pages-engagering-carat-0-3-カラット-tsx" */),
  "component---src-pages-engagering-carat-0-5-カラット-tsx": () => import("./../../../src/pages/engagering/carat/0.5カラット.tsx" /* webpackChunkName: "component---src-pages-engagering-carat-0-5-カラット-tsx" */),
  "component---src-pages-engagering-carat-index-tsx": () => import("./../../../src/pages/engagering/carat/index.tsx" /* webpackChunkName: "component---src-pages-engagering-carat-index-tsx" */),
  "component---src-pages-engagering-index-tsx": () => import("./../../../src/pages/engagering/index.tsx" /* webpackChunkName: "component---src-pages-engagering-index-tsx" */),
  "component---src-pages-engagering-material-index-tsx": () => import("./../../../src/pages/engagering/material/index.tsx" /* webpackChunkName: "component---src-pages-engagering-material-index-tsx" */),
  "component---src-pages-engagering-material-platinum-tsx": () => import("./../../../src/pages/engagering/material/platinum.tsx" /* webpackChunkName: "component---src-pages-engagering-material-platinum-tsx" */),
  "component---src-pages-engagering-material-whitegold-tsx": () => import("./../../../src/pages/engagering/material/whitegold.tsx" /* webpackChunkName: "component---src-pages-engagering-material-whitegold-tsx" */),
  "component---src-pages-engagering-price-10-万円以上-tsx": () => import("./../../../src/pages/engagering/price/10万円以上.tsx" /* webpackChunkName: "component---src-pages-engagering-price-10-万円以上-tsx" */),
  "component---src-pages-engagering-price-5-万円-tsx": () => import("./../../../src/pages/engagering/price/5万円.tsx" /* webpackChunkName: "component---src-pages-engagering-price-5-万円-tsx" */),
  "component---src-pages-engagering-price-6-万円-tsx": () => import("./../../../src/pages/engagering/price/6万円.tsx" /* webpackChunkName: "component---src-pages-engagering-price-6-万円-tsx" */),
  "component---src-pages-engagering-price-9-万円-tsx": () => import("./../../../src/pages/engagering/price/9万円.tsx" /* webpackChunkName: "component---src-pages-engagering-price-9-万円-tsx" */),
  "component---src-pages-engagering-price-index-tsx": () => import("./../../../src/pages/engagering/price/index.tsx" /* webpackChunkName: "component---src-pages-engagering-price-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kiyaku-tsx": () => import("./../../../src/pages/kiyaku.tsx" /* webpackChunkName: "component---src-pages-kiyaku-tsx" */),
  "component---src-pages-low-priced-tsx": () => import("./../../../src/pages/low-priced.tsx" /* webpackChunkName: "component---src-pages-low-priced-tsx" */),
  "component---src-pages-marriagering-design-diamond-tsx": () => import("./../../../src/pages/marriagering/design/diamond.tsx" /* webpackChunkName: "component---src-pages-marriagering-design-diamond-tsx" */),
  "component---src-pages-marriagering-design-index-tsx": () => import("./../../../src/pages/marriagering/design/index.tsx" /* webpackChunkName: "component---src-pages-marriagering-design-index-tsx" */),
  "component---src-pages-marriagering-design-straight-tsx": () => import("./../../../src/pages/marriagering/design/straight.tsx" /* webpackChunkName: "component---src-pages-marriagering-design-straight-tsx" */),
  "component---src-pages-marriagering-design-wave-tsx": () => import("./../../../src/pages/marriagering/design/wave.tsx" /* webpackChunkName: "component---src-pages-marriagering-design-wave-tsx" */),
  "component---src-pages-marriagering-index-tsx": () => import("./../../../src/pages/marriagering/index.tsx" /* webpackChunkName: "component---src-pages-marriagering-index-tsx" */),
  "component---src-pages-marriagering-material-index-tsx": () => import("./../../../src/pages/marriagering/material/index.tsx" /* webpackChunkName: "component---src-pages-marriagering-material-index-tsx" */),
  "component---src-pages-marriagering-material-k-18-gold-tsx": () => import("./../../../src/pages/marriagering/material/k18gold.tsx" /* webpackChunkName: "component---src-pages-marriagering-material-k-18-gold-tsx" */),
  "component---src-pages-marriagering-material-platinum-tsx": () => import("./../../../src/pages/marriagering/material/platinum.tsx" /* webpackChunkName: "component---src-pages-marriagering-material-platinum-tsx" */),
  "component---src-pages-marriagering-price-1-万円-tsx": () => import("./../../../src/pages/marriagering/price/1万円.tsx" /* webpackChunkName: "component---src-pages-marriagering-price-1-万円-tsx" */),
  "component---src-pages-marriagering-price-2-万円-tsx": () => import("./../../../src/pages/marriagering/price/2万円.tsx" /* webpackChunkName: "component---src-pages-marriagering-price-2-万円-tsx" */),
  "component---src-pages-marriagering-price-3-万円以上-tsx": () => import("./../../../src/pages/marriagering/price/3万円以上.tsx" /* webpackChunkName: "component---src-pages-marriagering-price-3-万円以上-tsx" */),
  "component---src-pages-marriagering-price-index-tsx": () => import("./../../../src/pages/marriagering/price/index.tsx" /* webpackChunkName: "component---src-pages-marriagering-price-index-tsx" */),
  "component---src-pages-material-tsx": () => import("./../../../src/pages/material.tsx" /* webpackChunkName: "component---src-pages-material-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-privacy-p-tsx": () => import("./../../../src/pages/privacy-p.tsx" /* webpackChunkName: "component---src-pages-privacy-p-tsx" */),
  "component---src-pages-quality-tsx": () => import("./../../../src/pages/quality.tsx" /* webpackChunkName: "component---src-pages-quality-tsx" */),
  "component---src-pages-rental-tsx": () => import("./../../../src/pages/rental.tsx" /* webpackChunkName: "component---src-pages-rental-tsx" */),
  "component---src-pages-review-tsx": () => import("./../../../src/pages/review.tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-salon-tsx": () => import("./../../../src/pages/salon.tsx" /* webpackChunkName: "component---src-pages-salon-tsx" */),
  "component---src-templates-engagering-tsx": () => import("./../../../src/templates/engagering.tsx" /* webpackChunkName: "component---src-templates-engagering-tsx" */),
  "component---src-templates-marriagering-tsx": () => import("./../../../src/templates/marriagering.tsx" /* webpackChunkName: "component---src-templates-marriagering-tsx" */),
  "component---src-templates-ranking-tsx": () => import("./../../../src/templates/ranking.tsx" /* webpackChunkName: "component---src-templates-ranking-tsx" */)
}

