import { createTheme } from '@mui/material'

const fontFamily = ['Noto Serif JP', 'serif', 'sans-serif'].join(',')
const theme = createTheme({
    typography: {
        fontFamily: fontFamily,
    },
    palette: {
        primary: {
            main: '#3894c2',
        },
        secondary: {
            main: '#f3f3f3',
        },
        background: {
            default: '#ffffff',
        },
        action: {
            active: '#3894c2',
            hover: '#3894c2',
        },
    },
})

export default theme
